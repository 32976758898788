import { useRef } from 'react';
import Advantages from './components/Advantages';

import Awards from './components/Awards';
import Courses from './components/Courses';
import FirstLesson from './components/FirstLesson';
import Info from './components/Info';
import Intro from './components/Intro';
import Landing from './components/Landing';
import Map from './components/Map';
import './style/App.css';

function App() {

  const scrollBodyRef = useRef()
  const executeScroll = () => window.scrollTo({ behavior: 'smooth', top: scrollBodyRef.current.offsetTop })

  return (
    <div className="App">
      <Landing scroll={executeScroll} />
      <Intro />
      <Advantages />
      <Courses rf={scrollBodyRef} />
      <FirstLesson />
      <Awards />
      <Map />
      <Info />
    </div>
  );
}

export default App;
