import React from 'react'
import '../style/Awards.css'

function Awards() {
    return (
        <div className="awards-container">
            <h3 className="heading-awards">Международный успех</h3>
            <p className="success-des">
                Наши ученики участвовали во многих международных соревнованиях,
                путешествуя по таким странам, как Китай, Корея, Великобритания и США.
                Они выиграли множество призов и наград на соревнованиях VEX и
                других конкурсах робототехники.
            </p>
            <div className="awards-card">
                <div id="awards-image1" className="awards-image"></div>
                <div id="awards-image2" className="awards-image"></div>
                <div id="awards-image3" className="awards-image"></div>
            </div>
        </div>
    )
}

export default Awards