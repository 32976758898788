import React from 'react';
import '../style/Landing.css'

function Landing({ scroll = null }) {
    return (
        <div className="landing">
            <div className="landing-info">
                <h1>International Robotics Academy</h1>
                <div className="landing-text-container"><div><h6>Школа робототехники для детей</h6></div>
                    <pre> </pre><div><h6>от 7 до 17 лет.</h6></div></div>
                <button onClick={scroll} className="courses-button">Подробнее</button>
            </div>
        </div>
    );
}

export default Landing;