import React from 'react';
import '../style/Info.css'
import instagramLogo from '../images/instagram_logo.png'
import facebookLogo from '../images/facebook_logo.png'

function Info() {
    return (
        <div className="section-container" >
            <div className="info-container">
                <h5>International Robotics Academy</h5>
                <div className="info-logo">
                    <a href='https://instagram.com/roboticsacademy.kz?igshid=YmMyMTA2M2Y='>
                        <img style={{'width': '30px', "paddingTop": "5px"}} src={instagramLogo} alt="instagram" />
                    </a>
                    <a href="https://m.facebook.com/roboticsacademy.kz">
                        <img style={{'width': '25px'}} src={facebookLogo} alt="facebook" />
                    </a>
                </div>
            </div>
        </div >
    )
}

export default Info