import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

let firebaseConfig = {
    apiKey: "AIzaSyCTN0A00xNf3prOqfF9TsMi6hYzsQo70Vg",
    authDomain: "robotics-academy-62d40.firebaseapp.com",
    projectId: "robotics-academy-62d40",
    storageBucket: "robotics-academy-62d40.appspot.com",
    messagingSenderId: "758017110974",
    appId: "1:758017110974:web:102e3a96164c5ba5debfef",
    measurementId: "G-62BFW91H0R"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth()
const db = firebase.firestore()

export { auth, db, firebase }
