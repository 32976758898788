import React, { useEffect, useState } from 'react';
import { db, firebase } from '../backend/db'
import emailjs from 'emailjs-com'
import '../style/FirstLesson.css'


function FirstLesson() {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [age, setAge] = useState('')
    const [experience, setExperience] = useState(false)
    const [registered, setRegistered] = useState(false)

    async function sendEmail(e) {
        e.preventDefault();

        // validate
        if (name === '' || phone === '' || age === '') {
            alert('Заполните все поля!');
            return
        }

        // send email
        emailjs.sendForm('service_3nconka', 'template_0e4lz84', e.target, 'user_P6f4Idl2If28v6hLZrZpK')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        // add record to collection
        const studentsRef = db.collection('students')
        setRegistered(true)
            await studentsRef.add({
                name,
                phone,
                age,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            })

        // send data to api
        try {
            await fetch(`${process.env.REACT_APP_API_URL}/leads`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "name": name,
                    "phone": phone,
                    "age": age,
                    "has_experience": experience
                })
            })

            console.log('data sent to api')
        } catch (error) {
            console.log('coulnt send data to api: ', error)
        }

        // set registered
        setRegistered(true)

        // reset fields
        setName('')
        setPhone('')
        setAge('')       
        setExperience(false)
    }

    useEffect(() => {
        setTimeout(() => {
            setRegistered(false)
        }, 5000)
    }, [registered])

    return (
        <div className="container">
            <div className="first-lesson">
                <h3>Первый урок бесплатно</h3>
                <div style={{width: '100%'}}>
                    <form onSubmit={sendEmail} className="form">
                        <input className="phone" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Телефон" name="number" />
                        <input className="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Имя ребенка" name="name" />
                        <input className="age" value={age} onChange={(e) => setAge(e.target.value)} placeholder="Возраст ребенка" name="age" />
                        <div className="flex w-full">
                            <label htmlFor="experience" className="text-left">Есть ли опыт в робототехнике?</label>
                            <input type="checkbox" className="experience" checked={experience} value={experience} onChange={(e) => setExperience(!experience)} name="experience" />
                        </div>
                        <button type="submit" className='mx-auto'>Записаться на пробный урок</button>
                    </form>
                    {
                        registered && (
                            <h6 className="feedback-text">Вы успешно зарегистрировались! Скоро с вами свяжутся.</h6>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default FirstLesson