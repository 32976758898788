import React from 'react';
import '../style/Map.css'

function Map() {
    return (
        <div className="map-container">
            <h3 className="heading-map">Местоположение</h3>
            <div className="map-info">
                <div className="map-text">
                    <h5>Моб. телефон: +7 708 577 8667</h5>
                    <h5>Адресс: ул. Сатпаева, 22(уг.ул.Байтурсынова)</h5>
                    <h5>roboticsacademy.kz@gmail.com</h5>
                </div>
                <div className="map" style={{ 'position': 'relative', 'overflow': 'hidden' }}><a href="https://yandex.kz/maps/162/almaty/?utm_medium=mapframe&utm_source=maps" style={{ 'color': '#eee', 'fontSize': '12px', 'position': 'absolute', 'top': '0px' }}>Алматы</a><a href="https://yandex.kz/maps/162/almaty/house/Y08YfwRjT0wAQFppfX5yeHthYA==/?ll=76.934628%2C43.240674&utm_medium=mapframe&utm_source=maps&z=18" style={{ 'color': '#eee', 'fontSize': '12px', 'position': 'absolute', 'top': '14px' }}>Яндекс.Карты — транспорт, навигация, поиск мест</a><iframe title="map" src="https://yandex.com/map-widget/v1/?um=constructor%3A422d586024f17314fa43b4c888f50f0deacc2a0facf3ec5d6c7b9218e4201169&amp;source=constructor" width="634" height="467" frameBorder="0"></iframe></div>
            </div>
        </div>
    )
}

export default Map