import React from 'react';
import '../style/Courses.css'
import vexGo from '../images/vex_go.png'
import vexIq from '../images/vex_iq.jpg'
import vexV5 from '../images/vex_v5.jpg'
import ev3 from '../images/ev3.jpeg'
import math from '../images/math.jpeg'

function Courses({ rf = null }) {
    return (
        <div ref={rf}>
            <h3 className="h3-courses">Курсы</h3>
            <div className="courses">
                <div className="card">
                    <h4>Vex GO</h4>
                        <img src={vexGo} alt="vex go" />
                    <div className="course-info">
                        <p className="course-text">
                            Курс VEX GO это занятия направленные на развитие инженерного мышления,
                            программирования, моторики рук и работы в команде. На занятиях дети строят
                            различных роботов и механизмы, программируют и тестируют их.
                        </p>
                    </div>
                </div>
                <div className="card">
                    <h4>Vex IQ</h4>
                        <img src={vexIq} alt="vex iq" />
                    <div className="course-info">
                        <p className="course-text">
                            VEX IQ - это программируемая, конструируемая платформа, для более крупных,
                            мощных и функциональных роботов. Используя VEX IQ, наши ученики участвуют
                            в крупнейшем международном соревновании по робототехнике
                            в возрасте от 11 до 14 лет.
                        </p>
                    </div>
                </div>
                <div className="card">
                    <h4>Vex V5</h4>
                        <img src={vexV5} alt="vex v5" />
                    <div className="course-info">
                        <p className="course-text">
                            V5 - продвинутая платформа по робототехнике для учеников от 13 до 17 лет.
                            Ребята строят роботов из металла и электроники. Каждый год ученики принимают
                            участие в соревнованиях международного уровня находя разных друзей и
                            выигрывая призовые места.
                        </p>
                    </div>
                </div>
                <div className="card">
                    <h4>EV3</h4>
                        <img src={ev3} alt="ev3" />
                    <div className="course-info">
                        <p className="course-text">
                            Курсы по робототехнике для начинающих и продвинутых робототехников.
                            На курсе ребенок научится конструировать и программировать робота на базе Lego EV3.
                            Преподавание ведется тренером с опытом участия в WRO (Всемирная олимпиада роботов).
                        </p>
                    </div>
                </div>
                <div className="card">
                    <h4 id="math">Математика</h4>
                    <img src={math} alt="ev3" />
                    <div className="course-info">
                        <p className="course-text">
                            Подготовка по математике к ЕНТ, МЭСК, A-level, SAT, ЕГЭ и
                            других экзаменов для 9-12 классов.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Courses;