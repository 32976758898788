import React from 'react';
import '../style/Advantages.css'
import money from '../images/money.png'
import presentation from '../images/presentation.png'
import badge from '../images/badge.png'
import success from '../images/success.png' 

function Advantages() {
    return (
        <div>
            <h3 className="heading-awards">Преимущества</h3>
            <div className="advantages">
                <div className="pros-card">
                    <img src={money} alt="money" />
                    <h5>Доступные цены</h5>
                    <h6>Комфортная стоимость от 24 000 тенге (месячный абонемент) и семейные скидки</h6>
                </div>
                <div className="pros-card">
                    <img src={presentation} alt="presentation" />
                    <h5>Уникальная программа обучения</h5>
                    <h6>Курсы, адаптированные для всех возрастных категорий от 7 до 17 лет</h6>
                </div>
                <div className="pros-card">
                    <img src={badge} alt="badge" />
                    <h5>Гарантированное развитие ребенка</h5>
                    <h6>Во время занятий ученики развивают критическое мышление, мелкую моторику рук и логику</h6>
                </div>
                <div className="pros-card">
                    <img src={success} alt="success" />
                    <h5>Победы</h5>
                    <h6>7-летний успех на международных соревнованиях (США, Китай, Южная Корея и т.д.)</h6>
                </div>
            </div>
        </div>
    )
}

export default Advantages