import React from 'react';
import '../style/Intro.css'

import intro_img from '../images/Intro.jpeg'

function Intro() {
    return (
        <div className="intro-container">
            <div className="intro">
                <h6 className="intro-text">
                    International Robotics Academy - это образовательный центр по
                    робототехнике, для талантливых детей от 7 до 17 лет, в особенности
                    заинтересованных в Технологии и IT.
                </h6>
                <img className="intro-image" src={intro_img} alt="intro" />
            </div>
        </div>
    )
}

export default Intro